
function Popup(title, data) {
    // var myWindow = window.open('', 'my div', 'height=400,width=600');
    const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write("\n      <html>\n        <head>\n          <title>" + title + "</title>\n          <style>\n            " + "" + "\n          </style>\n        </head>\n    <body onload=\"window.print();window.close();\">" + data + "</body>\n      </html>");
    popupWin.document.close();

}
$(function() {
   // alert('Hello, custom js');
});